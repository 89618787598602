import L, {LatLng} from "leaflet";

export interface ApiError {
    error: string;
    message: string;
}

export interface Player {
    id: number;
    nickname: string;
}

export interface PlayerPosition {
    latLng: LatLng,
    accuracy: number,
    speed: number,
}

export interface BuildingTypeProduction {
    type: ResourceType;
    levels: {1: number, 2: number, 3: number, 4: number, 5: number};
}

export interface BuildingType {
    slug: string;
    name: string;
    description: string;
    icon: string;
    cost: Array<Resource>;
    build_time: number;
    range: number;
    max_level: number;
    unique_in_world: boolean;
    unique_in_region: boolean;
    productions: Array<BuildingTypeProduction>;
}

export enum BuildingState {
    BUILDING = 'building',
    BUILT = 'built',
    DEMOLISHING = 'demolishing',
    DEMOLISHED = 'demolished',
    UPGRADING = 'upgrading',
}

export interface Building {
    id: string;
    type: BuildingType;
    name: string;
    position: L.LatLng;
    player: Player;
    state: BuildingState;
    level: number;
    upgrade_cost: Array<Resource>;
    state_valid_to: any;
    stock: Array<Resource>;
}

export interface ResourceType {
    slug: string;
    name: string;
}

export interface ResourceRegionType {
    slug: string;
    name: string;
}

export interface Resource {
    type: ResourceType;
    amount: number;
}

export interface Region {
    id: number;
    name: string;

    level: string;
    level_name: string;

    owner_id: number;
    owner_name: string;

    resource_amounts: Array<Resource>;
}

export interface ResourceRegion {
    id: number;
    name: string;
    type: ResourceRegionType;
    resource_amounts: Array<Resource>
}