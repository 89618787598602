import { defineStore } from "pinia";
import { PlayerService } from "../../common/api.service";
import {Building, Player, PlayerPosition, Region, Resource} from "../../type";

interface State {
    position?: PlayerPosition,
    player?: Player;
    last_logged_at?: string;
    resources?: Array<Resource>;
    current_region?: Region,
    current_region_buildings?: Building[],
}

export const usePlayerStore = defineStore('player', {
    state: () : State => ({
        position: undefined,
        player: undefined,
        last_logged_at: undefined,
        resources: undefined,
        current_region: undefined,
        current_region_buildings: undefined,
    }),
    getters: {
        findResource(state: State) {
            return (slug: string) => {
                return state.resources?.find(
                    (resource) => resource.type.slug === slug
                ) || null;
            }
        },
        findBuildingByType(state: State) {
            return (typeSlug: string) => {
                return state.current_region_buildings?.find(
                    (building) => building.type.slug === typeSlug
                ) || null;
            }
        }
    },
    actions: {
        async fetchPlayer() {
            PlayerService.get()
                .then((response) => {
                    this.player = response.data.player;
                    this.resources = response.data.resources;
                    this.last_logged_at = response.data.lastLoggedAt;
                });
        },

        async update(posData: PlayerPosition) {
            this.position = posData;
            PlayerService.updatePlayer(posData)
                .then((response) => {
                    this.player = response.data.player.player;
                    this.resources = response.data.player.resources;
                    this.current_region = response.data.region;
                    this.current_region_buildings = response.data.current_region_buildings ;
                });
        },

        async clear() {
            this.position = undefined;
            this.player = undefined;
            this.lastLoggedAt = undefined;
            this.resources = undefined;
            this.currentRegion = undefined;
            this.currentRegionBuildings = undefined;
        }
    },
})