import { defineStore } from "pinia";
import { BuildingService } from "../../common/api.service";
import L, {LatLng} from "leaflet";
import {ApiError, Building, BuildingType, Resource, ResourceRegion} from "../../type";

export interface BuildingState {
    currentType?: BuildingType;
    currentPosition?: LatLng;

    types?: Array<BuildingType>;
    typesLoading: boolean;
    typesError: ApiError|null;

    buildings?: Array<Building>;
    buildingsLoading: boolean;
    buildingsError: ApiError|null;

    resourcesInRange?: Array<{distance:number, amounts: Array<Resource>, resource_region: ResourceRegion}>;
    resourcesInRangeLoading: boolean;
    resourcesInRangeError: ApiError|null;

    pickUpLoading: boolean;
    pickUpError: ApiError|null;

    upgradeLoading: boolean;
    upgradeError: ApiError|null;
}

export const useBuildingStore = defineStore('building', {
    state: () : BuildingState => ({
        currentType: undefined,
        currentPosition: undefined,

        types: undefined,
        typesLoading: false,
        typesError: null,

        buildings: undefined,
        buildingsLoading: false,
        buildingsError: null,

        resourcesInRange: undefined,
        resourcesInRangeLoading: false,
        resourcesInRangeError: null,

        pickUpLoading: false,
        pickUpError: null,

        upgradeLoading: false,
        upgradeError: null,
    }),
    getters: {
        findBuilding(state: BuildingState) {
            return (id: string) => state.buildings && state.buildings.find((building) => building.id === id);
        },

        findType(state: BuildingState) {
            return (slug: string) => state.types.find((type) => type.slug === slug);
        },
    },
    actions: {
        async fetchTypes() {
            this.types = undefined;

            this.loading = true;
            this.error = null;

            await BuildingService.getBuildingTypes()
                .then((response) => {
                    this.types = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async fetchBuildings() {
            this.buildingsLoading = true;
            this.buildingsError = null;
            this.buildings = undefined;

            await BuildingService.getBuildings()
                .then((response) => {
                    this.buildings = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.error = true;
                })
                .finally(() => {
                    this.buildingsLoading = false;
                });
        },

        async fetchResourcesInRange(buildingType: BuildingType) {
            this.resourcesInRangeLoading = true;
            this.resourcesInRangeError = null;
            this.resourcesInRange = undefined;

            let resourcesInRange = null;
            try {
                resourcesInRange = (await BuildingService.getResourcesInRange(buildingType.slug)).data;
            } catch (error) {
                console.log('Error fetching resources in range', error);
                this.resourcesInRangeError = error;
            }

            this.resourcesInRange = resourcesInRange;
            this.resourcesInRangeLoading = false;

            return resourcesInRange;
        },

        async build(type: BuildingType, name: string, position: L.LatLng, options: Object = {}) {
            this.buildingsLoading = true;
            this.buildingsError = null;

            let newBuilding = null;
            try {
                newBuilding = (await BuildingService.addBuilding(type.slug, name, position, options)).data;
            } catch (error) {
                console.log('Error adding building', error);
                this.buildingsError = error;
                throw error;
            }

            this.buildingsLoading = false;

            this.buildings.push(newBuilding);

            return newBuilding;
        },

        async pickUpResources(building: Building) {
            this.pickUpLoading = true;
            this.pickUpError = null;

            let updatedBuilding = null;
            try {
                updatedBuilding = (await BuildingService.pickUpResources(building.id)).data;

                // Update the building in the store
                this.buildings = this.buildings.map((b: Building) => b.id === updatedBuilding.id ? updatedBuilding : b);

            } catch (error) {
                console.log('Error picking up resources', error);
                this.pickUpError = error;
            }

            this.pickUpLoading = false;

            return updatedBuilding;
        },

        async upgradeBuilding(building: Building) {
            this.upgradeLoading = true;
            this.upgradeError = null;

            let updatedBuilding = null;
            try {
                updatedBuilding = (await BuildingService.upgradeBuilding(building.id)).data;
            } catch (error) {
                console.log('Error picking up resources', error);
                this.upgradeError = error;
            }

            this.upgradeLoading = false;

            // Update the building in the store
            this.buildings = this.buildings.map((b: Building) => b.id === updatedBuilding.id ? updatedBuilding : b);

            return updatedBuilding;
        },

        changeCurrentType(type: BuildingType|string) {
            if (typeof type === 'string') {
                type = this.findType(type);
            }
            this.currentType = type;
        },

        resetCurrentType() {
            this.currentType = undefined;
        },

        resetCurrentPosition() {
            this.currentPosition = undefined;
        },

    },
})