import {defineStore} from "pinia";
import {MapDataService, RegionService} from "../../common/api.service";
import {ResourceType} from "../../type";

interface Resource {
    id: number;
    name: string;
    type: ResourceType;
    resource_amounts: Array<{type: ResourceType, amount: number}>;
}

export interface ResourceState {
    resource?: Resource

    error: any,
    loading: boolean,
}

export const useResourceStore = defineStore('resource', {
    state: () : ResourceState => ({
        resource: undefined,

        error: null,
        loading: false,
    }),
    getters: {

    },
    actions: {
        fetchData(id: number) {
            if (this.resource?.id === id) {
                return;
            }

            this.resource = undefined;
            this.loading = true;

            RegionService.getResource(id)
                .then((response) => {
                    this.resource = response && response.data;
                })
                .catch((error) => {
                    this.error = error;
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
})