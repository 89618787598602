import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../view/HomeView.vue'
import DashboardView from "../view/DashboardView.vue";
import MapView from "../view/MapView.vue";
import HeroView from "../view/HeroView.vue";
import SignView from "../view/SignView.vue";

const routes = [
    { path: '/',            name: 'home',       component: HomeView },
    { path: '/dashboard',   name: 'dashboard',  component: DashboardView },
    { path: '/map',         name: 'map',        component: MapView },
    { path: '/hero',        name: 'hero',       component: HeroView },

    { path: '/sign',        name: 'sign',       component: SignView },
];

const router = createRouter({
    history: createWebHistory('/game'),
    routes,
    linkActiveClass: 'active',
});

export default router