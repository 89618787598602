const ID_TOKEN_KEY = "id_token";

const getData = () => {
    const data = window.localStorage.getItem(ID_TOKEN_KEY);

    if (!data) {
        return null;
    }

    return JSON.parse(data);
}

export const hasAccessToken = () : boolean => {
    return getData() !== null;
};

export const getAccessToken = () : string|null => {
    return getData()?.access_token;
};

export const getRefreshToken = () : string|null => {
    return getData()?.refresh_token;
};

export const getExpiresIn = () : number|null => {
    return getData()?.expires_in;
};

export const saveToken = (token: {access_token: string, refresh_token: string, expires_in: number}) => {
    window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token));
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { hasAccessToken, getAccessToken, getRefreshToken, getExpiresIn, saveToken, clearToken: destroyToken };