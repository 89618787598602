import { defineStore } from "pinia";
import {ApiError} from "../../type";

interface State {
    authenticated?: boolean,
    loading: boolean,
    error: ApiError|null,
}

// --------------------------------------------
// ---------- DO NOT USE API SERVICE ----------
// ----- IT MAY CAUSE INFINITE LOOP ERROR -----
// --------------------------------------------

export const useSignStore = defineStore('sign', {
    state: () : State => ({
        authenticated: undefined,
        loading: false,
        error: undefined,
    }),
})