import {LatLng} from "leaflet";
import {usePlayerStore} from "../store/player";

export default {
    geolocationWatchID: undefined,

    watchGeolocation(): boolean {
        const geolocation = this._getGeolocation();

        if (!geolocation) {
            return false;
        }

        const playerStore = usePlayerStore();

        const success = async function (position: GeolocationPosition) {
            // update player position
            await playerStore.update({
                latLng:     new LatLng(
                    position.coords.latitude,
                    position.coords.longitude
                ),
                accuracy:   position.coords.accuracy,
                speed:      position.coords.speed,
            })
        };

        const error = function () {
            console.error('geolocation error');
        }

        const options : PositionOptions = {
            enableHighAccuracy: true,
            maximumAge: 10000,
            timeout: 10000,
        };

        this.geolocationWatchID = geolocation.watchPosition(success, error, options);

        return true;
    },

    clearWatch(): boolean {
        const geolocation = this._getGeolocation();

        if (!geolocation) {
            return false;
        }

        geolocation.clearWatch(this.geolocationWatchID);

        return true;
    },

    /**
     * @private
     * @returns {Geolocation}
     */
    _getGeolocation() : Geolocation {
        if (!("geolocation" in navigator)) {
            console.log('geolocation is not available :(');
            return;
        }

        return navigator.geolocation;
    },
}